import React from "react"
import "./SidebarFilter.scss"
import useSidebarCatFilter from "./CustomSidebarCatHooks"

function SidebarCatFilter(props) {
  const { handleClick } = useSidebarCatFilter(props)

  return (
    <li
      className={"SideBarCatFilter " + props.selected}
      onClick={handleClick}
      href="#"
    >
      {props.title}
    </li>
  )
}

export default SidebarCatFilter
