import { useState } from "react"
import useDealBrowser from "../Hooks/useDealBrowser"

const useSidebarFilter = callback => {
  const [checkedClasses, setClasses] = useState("Checkbox")
  const {
    filterList,
    state,
    setState,
    updateDiscountQuery,
  } = useDealBrowser()

  let title = callback.title

  const handleClick = event => {
    event.preventDefault()

    let clickedFilter = []
    clickedFilter = filterList.filter(x => x.name === title)
    console.log(clickedFilter)

    if (checkedClasses === "Checkbox") {
      setClasses("Checkbox selected")
      clickedFilter[0].checked = true
    } else {
      setClasses("Checkbox")
      clickedFilter[0].checked = false
    }

    let mergedFilters = []

    filterList.forEach(flItem => {
      mergedFilters.push(flItem)

      clickedFilter.forEach(cfItem => {
        if (flItem.name === cfItem.name) {
          mergedFilters[flItem.id] = cfItem
        }
      })
    })

    // let mergedFilters = Object.values({...filterList, ...clickedFilter })
    let appendFilterObj = { filters: [] }
    appendFilterObj.filters = mergedFilters

    setState({ ...state, ...appendFilterObj })
    updateDiscountQuery()
  }

  return {
    handleClick,
    checkedClasses,
  }
}

export default useSidebarFilter
