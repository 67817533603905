import React from "react"
import "./SidebarFilter.scss"
import useSidebarFilter from "./CustomSidebarHooks"

const SidebarFilter = props => {
  const { checkedClasses, handleClick } = useSidebarFilter(props)

  return (
    <a className="SideBarFilter" onClick={handleClick} href="#">
      <div className={checkedClasses}></div>
      <div> {props.title} </div>
    </a>
  )
}

export default SidebarFilter
