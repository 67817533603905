import useDealBrowser from "../Hooks/useDealBrowser"

const useSidebarCatFilter = callback => {
  const {
    categoryList,
    state,
    setState,
    updateDiscountQuery,
  } = useDealBrowser()

  let title = callback.title

  const handleClick = event => {
    event.preventDefault()
    console.log(title)

    let newCategoryList = categoryList

    newCategoryList.forEach(item => {
      if (title === item.name) newCategoryList[item.id].selected = "selected"
      else newCategoryList[item.id].selected = null
    })

    setState({ ...state, ...newCategoryList })
    updateDiscountQuery()
  }

  return {
    handleClick,
  }
}

export default useSidebarCatFilter
