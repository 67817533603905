import React from "react"
import "./Row.scss"
import { Link } from "gatsby"

const Row = (props) => {

  if (typeof(props.data) === "undefined")
  {
    props = {
      data : {
        discount_type : "Percent off",
        discount_value : "--",
        discount_scope : "--",
        title : "",
        store : {
          url: "",
          logo: ""
        }
      }

    }
  }

  let discountNum = ""
  let discountTitle = ""

  switch (props.data.discount_type) {
    case "Percent off":
    case "Partial, Up to":
    case "Partial, Percent off":
    case "New user":
      discountNum = `${props.data.discount_value}% off`
      discountTitle = `${props.data.discount_scope}`
      break

    case "Up to, Percent off":
      discountNum = `${props.data.discount_value}% off (up to) `
      discountTitle = `${props.data.discount_scope}`
      break

    default:
        discountTitle = `${props.data.title}`
      break
  }

  let logoHtml = props.data.store.logo !== "" ?  (<img src={props.data.store.logo} />) : ( <div></div>)

  return (
    <div className="Row">
        <div className="Row-Sale-Logo pure-u-1-3  pure-u-sm-6-24">
        <a href={props.data.store.url}>
          {logoHtml}
        </a>
        </div>
        <div className="Row-Sale-Desc pure-u-1-3 pure-u-sm-6-24">
          <div className="Row-Sale-Disc-Num"><a href={props.data.store.url}>{discountNum}</a></div>
          <div className="Row-Sale-Disc-Title"><a href={props.data.store.url}>{discountTitle}</a></div>
        </div>
        <div className="Row-Sale-Desc pure-hidden-sm pure-u-sm-6-24">
          <div className="Row-Sale-Disc-Num">2 days ago</div>
          <div className="Row-Sale-Disc-Title">Posted</div>
        </div>
        <div className="pure-u-1-3 pure-u-sm-8-24 Row-links">
          <div className="btn"><a href={props.data.store.url}>Shop</a></div>
          <div className="btn"><Link to="/alert">Get Alerts</Link></div>
        </div>
    </div>
  )
}

export default Row
