import { useContext } from "react"
import { Context } from "../Components/Context"
import axios from "axios"

const useDealBrowser = () => {
  const [state, setState] = useContext(Context)

  const filterBySelections = param => {
    let stays = {}
    let checked = { level_1: false, level_2: false }

    for (let i = 0; i < state.filters.length; i++) {
      if (state.filters[i].checked === true) {
        if (state.filters[i].category_level === 1) checked.level_1 = true

        if (state.filters[i].category_level === 2) checked.level_2 = true

        if (state.filters[i].category_level === 1) {
          if (state.filters[i].name === param.discount_scope) {
            stays.level_1 = true
          }
        }
        if (state.filters[i].category_level === 2) {
          let foundCat = param.categories.filter(
            item => state.filters[i].name === item.title,
          )
          if (foundCat.length > 0) {
            stays.level_2 = true
          }
        }
      }
    }

    if (checked.level_1 && checked.level_2)
      stays = stays.level_1 && stays.level_2

    if (
      (checked.level_1 && !checked.level_2) ||
      (!checked.level_1 && checked.level_2)
    )
      stays = stays.level_1 || stays.level_2

    return stays
  }

  const filterByCategories = param => {
    let selectedCategory = state.categories.filter(
      item => item.selected === "selected",
    )

    if (selectedCategory[0].name === "All") {
      return true
    }

    let stays = false

    param.categories.forEach(element => {
      if (selectedCategory[0].name === element.title) stays = true
    })

    return stays
  }

  let filteredDiscounts = { discounts: [] }
  let apiDiscounts = { apiDiscounts : [] }

  const updateDiscountQuery = async () => {

    setState( {...state, ...{isLoaded : false } })
    let selectedFilters = state.filters.filter(item => item.checked === true)

    const fetchData = async () => {
      if (state.apiDiscounts.length === 0){
        const result = await axios(
          '/api/discounts?verified=true',
        );

        apiDiscounts.apiDiscounts = result.data
        filteredDiscounts.discounts = result.data
      }
      else {
        apiDiscounts = state.apiDiscounts
        filteredDiscounts.discounts = state.apiDiscounts
      }

      filteredDiscounts.discounts = filteredDiscounts.discounts.filter(
        filterByCategories,
      )

      if (selectedFilters.length > 0) {
        filteredDiscounts.discounts = filteredDiscounts.discounts.filter(
          filterBySelections,
        )
      }

      setState( {...state, ...filteredDiscounts, ...apiDiscounts, ...{isLoaded : true } });
    }
    fetchData()
  }

  return {
    updateDiscountQuery,
    setState,
    state,
    categoryList: state.categories,
    trackList: state.tracks,
    filterList: state.filters,
    isLoaded : state.isLoaded
  }
}

export default useDealBrowser
