import React from "react"
import "antd/dist/antd.css"
import "purecss/build/pure-min.css"
import "purecss/build/grids-responsive.css"
import "./IndexPage.scss"
import Layout from "../Components/Layout"
import { ContextProvider } from "../Components/Context"
import Body from "../Components/Body"

const IndexPage = () => {
  return (
    <Layout pageClassName="IndexPage">
      <ContextProvider>
        <Body />
      </ContextProvider>
    </Layout>
  )
}

export default IndexPage
