import React, { useEffect } from "react"
import axios from "axios"
import Row from "./Row.js"
import Sidebar from "./Sidebar.js"
import useDealBrowser from "../Hooks/useDealBrowser"
import { Spin } from 'antd'

function Body() {
  const { setState, state, isLoaded } = useDealBrowser();
  let dataObj = { discounts: []}
  let apiDiscounts = { apiDiscounts : [] }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("/api/discounts?verified=true")
      dataObj.discounts = result.data
      apiDiscounts.apiDiscounts = result.data
      setState( {...state, ...dataObj, ...apiDiscounts, ...{isLoaded : true} })
    }
    if (isLoaded === false) {
      fetchData()
    }
  }, [
    apiDiscounts,
    dataObj,
    state,
    setState,
    isLoaded
  ])

  const loadStyle = {
    margin: '40px auto',
    maxWidth: '1000px',
    textAlign: 'center'
  };

  return (
    <div className="Main">
      <div className="pure-g">
        <div className="pure-hidden-sm pure-u-md-3-8 pure-u-lg-1-3">
          <Sidebar />
        </div>
        <div className="pure-u-sm-1-1 pure-u-md-5-8 pure-u-lg-2-3">
          <h1> Today's Sales </h1>
          {isLoaded ? (
            state.discounts ? (
              state.discounts.map(item => <Row key={item.id} data={item} />)
            ) : (
              <p>There was an issue loading deals.</p>
            )
          ) : (
            <div style={loadStyle}>
              <Spin size="large" tip="Loading..." >
              <Row key={1000} />
              <Row key={1001} />
              <Row key={1002} />
              <Row key={1003} />
              <Row key={1004} />
              <Row key={1005} />
              </Spin>
            </div>

          )}
        </div>
      </div>
    </div>
  )
}

export default Body
