import React from "react"
import "./Sidebar.scss"
import SidebarFilter from "./SidebarFilter"
import SidebarCatFilter from "./SidebarCatFilter"
import useDealBrowser from "../Hooks/useDealBrowser"

const Sidebar = () => {
  const { state } = useDealBrowser()

  return (
    <div className="Sidebar">
      <div className="Sidebar-section">
        <h2>Categories</h2>
        <div>
          <ul className="sidebar-categories">
            {state.categories.map((cat, index) => (
              <SidebarCatFilter
                key={cat.id}
                title={cat.name}
                selected={cat.selected}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="Sidebar-section">
        <h2>Sales</h2>
        <div>
          <ul className="sidebar-categories">
            {state.filters.map((filter, index) => {
              if (filter.category_level === 1) {
                return (
                  <li key={filter.id}>
                    <SidebarFilter
                      key={filter.id}
                      title={filter.name}
                      default={filter.checked ? "selected" : ""}
                    />
                  </li>
                )
              }
              else {
                return null
              }
            })}
          </ul>
        </div>
      </div>
      <div className="Sidebar-section">
        <h2>Refine</h2>
        <div>
          <ul className="sidebar-categories">
            {state.filters.map((filter, index) => {
              if (filter.category_level === 2) {
                return (
                  <li key={filter.id}>
                    <SidebarFilter
                      key={filter.id}
                      title={filter.name}
                      default={filter.checked ? "selected" : ""}
                    />
                  </li>
                )
              }
              else {
                return null
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
